import { init } from '../node_modules/pqs-frontend/src/init'
import aws from './aws-exports'
import td from './imr-pr01-ast-003.json'
import model from '../node_modules/pqs-frontend/model/model.json'

console.log('APP: imrwa01', '003', td)

init({
  aws,
  td,
  model,
  upload: true,
  map: {
    prefix: 'imr-pr01-m0',
    suffix: '-003.png',
    width: 4500,
    height: 3125,
    minZoom: -4,
  }
})
